<!--故障列表 -->
<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-input
              placeholder="请输入手机号"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="searchPhone"
            >
            </el-input>
            <span class="TipsClass" v-if="errors.Phone">{{
              errors.Phone
            }}</span>
            <el-select
              clearable
              class="search-input"
              placeholder="请选择处理状态"
              size="small"
              v-model="searchBreakdown"
              @change="changeValue"
            >
              <el-option
                v-for="item in deviceBreakdownList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                {{ item.label }}
              </el-option>
            </el-select>
            <el-date-picker
              v-model="searchValue"
              size="small"
              type="date"
              placeholder="请选择创建时间"
              clearable
              value-format="YYYY-MM-DD"
              @change="pagesFault"
              style="width: 302px; margin-right: 4px"
            />
            <el-button type="primary" size="small" @click="pagesFault"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="630px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
<!--          <el-table-column type="selection" align="center" width="80" />-->
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          >
            <template #default="{ row }" v-if="item.prop === 'headUrl'">
              <img class="imgeTou" :src="row.headUrl" />
            </template>
            <template #default="{ row }" v-if="item.prop === 'status'">
             <span v-show="row.status == 0" style="color: #cd2b1d">{{ "未处理" }}</span>
             <span v-show="row.status == 1" style="color: #3ea422">{{"已处理"}}</span>
            </template>
          </el-table-column>
            <el-table-column label="操作" align="center" v-if="userLevel ==0">
              <template #default="{ row }">
                <el-button size="small" v-show="row.status == 0" type="primary" @click="editBreakdown(row)" plain>确认处理</el-button>
              </template>
            </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
            :total="total"
            @pageChange="handleSizeChange"
            @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
import { pagesFault, addFault } from "@/api/breakdownManagement/breakdownList.js";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
import pageNation from "@/components/base/pageNation";
export default {
  name: "AccountSearch",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      searchValue: null, //搜索创建时间
      tableData: [], // 表格数据
      errors: {}, //验证提示信息
      total: 0,
      pageSize: 10,
      currentPage: 1,
      userInfo: null,
      userLevel: 0, //用户等级
      selection: [],
      disabled: true,
      formData: {
        alarTime: [], //起始时间
      },
      tableColumnData: [
        // {
        //   prop: "userName",
        //   label: "用户名",
        // },
        // {
        //   prop: "headUrl",
        //   label: "头像",
        // },
        {
          prop: "phone",
          label: "手机号",
        },
        {
          prop: "deviceMac",
          label: "设备号",
        },
        {
          prop: "port",
          label: "端口号",
        },
        {
          prop: "status",
          label: "处理状态",
        },
        {
          prop: "feedback",
          label: "反馈内容",
        },
        {
          prop: "createTime",
          label: "反馈时间",
        },
      ],
      searchPhone: "",
      searchBreakdown: "",
      deviceBreakdownList: [
        {
          label: "已处理",
          value: 1,
        },
        {
          label: "未处理",
          value: 0,
        },
      ],
    });
    onMounted(() => {
        proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      proxy.$nextTick(() =>{
        proxy.userLevel = proxy.userInfo.chargingMerchant.level
      })
        methods.pagesFault();
    });
    // 节流搜索按钮
    const searchInput = debounces((value) => {
      // if(methods.validatePhone()) methods.pagesFault()
      methods.pagesFault()
    }, 500);
    const methods = {
      handleSizeChange(val) {
        proxy.pageSize = val
        methods.pagesFault()
      },
      handleCurrentChange(val) {
        proxy.currentPage = val
        methods.pagesFault()
      },
      // 手机号验证
      validatePhone() {
        if(!data.searchPhone) {
          data.errors = {}
          return true
        }
        if(!/^1[345678]\d{9}$/.test(data.searchPhone)) {
          data.errors = {
            Phone: "请输入正确的手机号...",
          };
          return false
        } else {
          data.errors = {};
          return true;
        }
      },
      changeValue(value) {
        // console.log('我触发了', val);
        proxy.searchBreakdown = value
        methods.pagesFault()
      },
      async pagesFault() {
        await requestApi(
          () => {
            const params = {
              current: proxy.currentPage,
              size: proxy.pageSize,
              endTime: proxy.searchValue,
              phone: proxy.searchPhone,
              status: proxy.searchBreakdown
            };
            // if(proxy.searchValue != null) params.endTime = proxy.searchValue
            // if(proxy.searchPhone) params.phone = proxy.searchPhone
            // if(proxy.searchBreakdown) params.status = proxy.searchBreakdown
            return pagesFault(params);
          },
          (res) => {
            if (res.code === 200) {
              // proxy.total = res.total
              proxy.total = res.data.total
              proxy.currentPage = res.data.current
              proxy.pageSize = res.data.size
              proxy.tableData = res.data.records.map((it) => {
                return {
                  createTime: it.createTime,   // 反馈时间
                  deviceMac: it.deviceMac, // 设备号
                  feedback: it.feedback, // 故障反馈内容
                  headUrl: it.headUrl,   // 头像
                  phone: it.phone,     // 手机号
                  status: it.status,  // 处理状态
                  userName: it.userName,   // 用户名
                  port: it.port,   // 端口号
                  faultId: it.faultId   // 主键
                };
              });
              console.log("成功", proxy.tableData);
            }
          }
        );
      },
      editBreakdown(row) {
        proxy.$msgbox
            .confirm("是否确认处理?", "警告", {
              closeOnClickModal: false,
              confirmButtonText: "是",
              cancelButtonText: "否",
              type: "warning",
            })
            .then(async () => {
              // console.log(row)
              await requestApi(
                () => {
                  let data = row
                  data.status = 1
                  console.log(data);
                   return addFault(data);
                },
                (res) => {
                  if (res.code === 200) {
                    proxy.$notify({
                      title: "成功",
                      message: '处理成功',
                      type: "success",
                      duration: 1000,
                    });
                    methods.pagesFault()
                  }
                }
              );
            })
            .catch(() => {
              // proxy.$refs.multipleTable.clearSelection();
            });
      },

    };
    return {
      ...toRefs(data),
      searchInput,
      ...methods,
    };
  },
};
</script>
<style lang="less" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
}
.page-container {
  width: 100%;
  //margin-top: -28px;
  .imgeTou {
    width: 35px;
    height: 35px;
  }
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}

.search-input-class {
  width: 100%;
}
.TipsClass {
  font-size: 12px;
  line-height: 18px;
  color: rgb(218, 10, 10);
}
/deep/.el-form-item__label {
  white-space: nowrap;
}
/deep/.el-form-item {
  width: 100%;
}
</style>
