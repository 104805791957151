import { axios } from '@/utils/request'

const api = {
    pagesFault: 'charging/fault/pagesFault', // 分页查询故障列表
    addFault: 'charging/fault/addFault',    // 新增（不传主键）/修改（传主键）
}
export default api

// 查询
export const pagesFault = (params) => {
    return axios({
        url: api.pagesFault,
        method: 'post',
        params
    })
}

// 新增（不传主键）/修改（传主键）
export const addFault = (data) => {
    return axios({
        url: api.addFault,
        method: 'post',
        data
    })
}